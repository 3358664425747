import React, { Fragment, useCallback, useEffect } from "react";
import { SWRConfig } from "swr";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import { AppHeader } from "./Components/AppHeader";
import Http from "./Utils/Http";
import ErrorBoundary from "./ErrorBoundary";
import { useDispatch, useSelector } from "react-redux";
import { AppFooter } from "./Components/AppFooter";
import Routes from "./Routes/Routes";
import { fetchBonusList, fetchGlobalVars } from "./Services/apis";
import * as types from "./Redux/types";
import Modal from "./Components/Modal";

function App({ history }) {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const init = useCallback(() => {
    Promise.all([fetchBonusList(), fetchGlobalVars()]).then((res) => {
      dispatch({ type: types.SET_BONUS_LIST, payload: res[0] });
      dispatch({ type: types.SET_GLOBAL_VAR, payload: res[1] });
    });
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          fetcher: (url) => Http.get(url).then((res) => res),
          refreshInterval: 15 * 60 * 1000,
          shouldRetryOnError: false,
          revalidateOnFocus: false,
          errorRetryInterval: 0,
          errorRetryCount: 2,
        }}
      >
        <Fragment>
          <AppHeader
            user={user}
            isAuthenticated={isAuthenticated}
            dispatch={dispatch}
          />
          <div className="bannerHome">
            <div className="divMainHome">
              <div id="divMain">
                <div id="divContent">
                  <Routes />
                  <div className="spacer30"></div>
                  <Modal />
                </div>
              </div>
            </div>
          </div>
          <AppFooter />
        </Fragment>
      </SWRConfig>
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </ErrorBoundary>
  );
}

export default App;
