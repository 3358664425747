import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setTournaments } from "../Redux/actions";
import { SET_TOURNAMENTS } from "../Redux/types";

export const SportsMenu = ({sports, tournaments, activePeriod}) => {
  
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleSportMenu = (e) => {
    // console.log(e);
    e.currentTarget.parentNode.classList.toggle("sel");
    // e.target.nextElementSibling.style.display = ''
  };

  const openEvent = (cid, tid, sid) => {
    const index = tournaments.findIndex(
      (tournament) => tournament.sport_tournament_id === tid
    );
    if (index !== -1) {
      // remove tournament
      tournaments.splice(index, 1);
      // update state
      dispatch({
        type: SET_TOURNAMENTS,
        payload: tournaments,
      });
    } else {
      const pathname = window.location.pathname;
      const urlSearch = window.location.search;
      const url = `/Sport/Odds?tid=${tid}&sid=${sid}&period=${activePeriod}`;
      if (pathname + urlSearch === url) {
        dispatch(setTournaments({ tid, sid }));
      } else {
        history.push(url);
      }
    }
    // dispatch(setTournaments({tid, sid}));
  };

  return (
    <div>
      <div className="sportPanel">
        <div className="menuContainer">
          <a className="liveButton" href="/Live/LiveDefault">
            <span>LiveBetting</span>
          </a>
          <ul className="sportMenu">
            {sports && sports?.map((sport) => (
              <li className="itemSport" key={sport.id}>
                <div className="head" onClick={(e) => toggleSportMenu(e)}>
                  <div className="nameSport">{sport.name} </div>
                  <span className="sportCount">{sport.total}</span>
                  <div className="groupLink"></div>
                </div>
                {sport.categories && (
                  <ul className="groupMenu">
                    {sport.categories.map((category) => (
                      <li
                        className="itemGroup"
                        key={`category_${category.sport_category_id}`}
                      >
                        <div
                          className="head"
                          onClick={(e) => toggleSportMenu(e)}
                        >
                          <div className="image"></div>
                          <div className="nameGroup ">{category.name}</div>
                          <span className="groupCount ">{category.total}</span>
                        </div>
                        {category.tournaments && (
                          <ul className="eventMenu">
                            {category.tournaments.map((tournament) => (
                              <li
                                className={`itemEvent ${
                                  tournaments.some(
                                    (item) =>
                                      item.sport_tournament_id ===
                                      tournament.sport_tournament_id
                                  )
                                    ? "sel"
                                    : ""
                                }`}
                                key={`tournament_${tournament.sport_tournament_id}`}
                                id={`TOR_${tournament.sport_tournament_id}`}
                                onClick={(e) =>
                                  openEvent(
                                    category.sport_category_id,
                                    tournament.sport_tournament_id,
                                    sport.sport_id
                                  )
                                }
                              >
                                <div className="head">
                                  <span className="nameEvent ">
                                    {tournament.name}
                                  </span>
                                  <span className="eventCount ">
                                    {tournament.total}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
