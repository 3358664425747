import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/_deposit.scss";
import { getAllBanks, bankWithdrawal, verifyBankAccount } from "../../Services/apis";
import { ErrorPopUp, SuccessPopUp } from "../../Utils/toastify";
import { toast } from "react-toastify";

const BankWithdrawal = () => {
  const { SportsbookGlobalVariable } = useSelector((state) => state.sportsBook);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState(null); 
  const [success, setSuccess] = useState(null); 
  const [inputObject, setObject] = useState({
    amount: 0,
    bank_code: "",
    account_number: '',
    account_name: '',
    recipient_code: '',
    type: 'online'
  });

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = (page) => {
    setLoading(true);

    getAllBanks()
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateAmount = (value) => {
    if (value === 0) {
      setObject({ ...inputObject, amount: 0 });
      return;
    }
    let currentAmount = inputObject.amount;
    if (currentAmount === "") {
      currentAmount = 0;
    }
    const newAmount = currentAmount + value;
    setObject({ ...inputObject, amount: newAmount });
  };

  const withdraw = () => {
    
    bankWithdrawal(inputObject)
      .then((r) => {
        if (r.success) {
          SuccessPopUp(r.message);

          setLoading(false);
        } else {
          ErrorPopUp(r.message);
          setLoading(false);
        }
        setLoading(false);
        // SuccessPopUp("Successfully sent request");
      })
      .catch((err) => {
        ErrorPopUp("Error occured");
        setLoading(false);
      });
  };

  const doVerify = async (account_number) => {
    setLoading(true);
    await verifyBankAccount({
      account_number,
      bank_code: inputObject.bank_code,
    })
      .then(res => {
        setLoading(false);
        if (res.success) {
          setVerified(true);
          setObject({...inputObject, 
            account_name: res.data.account_name,
            recipient_code: res.data.recipient_code,
            account_number
          })      
        } else {
          setVerified(false);
          setObject({...inputObject, 
            account_name: '',
            recipient_code: ''
          })    
          toast.error(res.message);
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.response.data.message);
      })
  };

  return (
    <div className="deposit">
      <div className="deposit-step">
        <div className="left">
          <h3>NOTE</h3>
          <p className="my-1 text-1">
            For easier and faster process verification, please ensure your bank
            account information matches the details in your {process.env.REACT_APP_NAME} account.
          </p>
          <p className="my-1 text-1">
            In line with the regulation, winings above <strong>#400,000</strong>
            require a valid means of ID for your withdrawal to be processed
            promptly. Simply email cs@...com with your user ID and a picture of
            your <strong> valid ID card.</strong>
          </p>
          {/* <p className="my-1 text-1">
            <strong>IMPORTANT UPDATE</strong> Payouts to{" "}
            <strong>FIRST BANK </strong> accounts take longer than 48hours due
            to <strong>delays from the bank. </strong>
          </p> */}
          {message && <div style={{padding: 20, backgroundColor: success ? 'green' : 'red', textAlign: 'center'}}>
            <h1 style={{color: 'white'}}>{message}</h1>
          </div>}
        </div>
        <div className="right">
          <p className="pl-1">Withdrawal</p>
          {/* <div className="flex by-1">
            <p onClick={() => updateAmount(1000)}>Total Balance: </p>
            <h5> N65,00000</h5>
          </div> */}
          <div>
            {/* <div className="form-input">
              <input
                name="amount"
                min={500}
                value={inputObject.amount}
                onChange={handleChange}
                type="number"
                className="deposit-input"
                step="100"
                maxLength={5}
                max="10000"
              />
              <div className="form-input--stake"> Min 100</div>
            </div> */}
            <div className="quickstake mt10">
              <div className="quickstake__item" onClick={() => updateAmount(0)}>
                {" "}
                Clear
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(100)}
              >
                {" "}
                +100
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(200)}
              >
                {" "}
                +200
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(500)}
              >
                {" "}
                +500
              </div>
              <div
                className="quickstake__item"
                onClick={() => updateAmount(1000)}
              >
                {" "}
                +1000
              </div>
            </div>
            <div className=" my-1">
              <label className="">Amount:</label>
              <input
                name="amount"
                value={inputObject.amount}
                onChange={handleChange}
                type="number"
                className="deposit-input"
              />
            </div>
            {SportsbookGlobalVariable.EnableBankAcct == 1 && 
              <>
                <div className=" my-1">
                  <label className="">Bank:</label>
                  <select
                    name="bank_code"
                    type="text"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="Card">Select a bank..</option>
                    {data &&
                      data?.map((item) => (
                        <option value={item.code}>{item?.name}</option>
                      ))}
                  </select>
                </div>
                <div className=" my-1">
                  <label className="">Account Number:</label>
                  <input 
                    name="account" 
                    type="text" 
                    className="deposit-input"
                    onChange={(e) => {
                      setObject({...inputObject, account_number: e.target.value});
                      if(e.target.value.length === 10) {
                        doVerify(e.target.value);
                      } 
                    }} 
                  />
                </div>
                <div className=" my-1">
                  <label className="">Account Name:</label>
                  <input
                    name="amount"
                    type="text"
                    disabled={true}
                    className="deposit-input"
                    value={inputObject.account_name}
                  />
                </div>
              </>
            }
          </div>

          <div className="btn-bank">
            <button onClick={withdraw}>PROCEED</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankWithdrawal;
