import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCashIn } from "../../Services/apis";

/**
 * packages
 */

export const CashIn = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCashin();
  }, [dispatch]);

  const fetchCashin = () => {
    setLoading(true);
    getCashIn()
      .then((r) => {
        if (r.success) {
          setDetails(r.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="homeShop logged">
        <table
          style={{
            borderWidth: "0px",
            borderStyle: "none",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <tr
            style={{
              borderWidth: "0px",
              borderBottom: "1px solid #f4f4f4",
              width: "100%",
              borderCollapse: "collapse",
              background: "transparent",
              color: "#4c4c4c",
              textAlign: "center",
            }}
            className="dgHdrStyle"
          >
            <th align="center" scope="col">
              ID
            </th>
            <th align="center" scope="col">
              DATE
            </th>
            <th align="center" scope="col">
              AMOUNT
            </th>
            <th align="center" scope="col">
              STATUS
            </th>
            <th align="center" scope="col">
              COMMENT
            </th>
          </tr>
          {loading ? (
            <tr style={{ textAlign: "center", width: "100%" }}>
              <h3>LOADING.....</h3>{" "}
            </tr>
          ) : (
            details &&
            details.map((item, i) => (
              <tr className="dgItemStyle" key={i}>
                <td align="center">{item?.id}</td>
                <td align="center"> {item?.date}</td>
                <td align="center">{item?.amount}</td>
                <td align="center">
                  {item?.status === 0 ? "Pending" : "Approved"}
                </td>
                <td align="center">{item?.comment}</td>
              </tr>
            ))
          )}
        </table>
      </div>
    </>
  );
};
