import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sendFund } from "../../Services/apis";
import { toast } from "react-toastify";
import { SHOW_MODAL } from "../../Redux/types";

const FundAccount = ({ user }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputObject, setObject] = useState({
    amount: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const sendToUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      username: user,
      amount: inputObject.amount,
      type: "internal",
      action: "deposit",
    };
    sendFund(payload)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          toast.success("Transaction was completed successfully");
          dispatch({
            type: SHOW_MODAL,
            payload: {
              open: false,
            },
          });
        } else {
          toast.success(res.message);
        }
      })
      .catch(
        (err) => toast.error("Internal server error!"),
        dispatch({
          type: SHOW_MODAL,
          payload: {
            open: false,
          },
        }),
        setLoading(false)
      );
  };

  return (
    <form className="expense-form">
      <div className="expense-input">
        <label>AMOUNT</label>
        <input type="number" onChange={handleChange} name="amount" />
      </div>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value="TRANSFER"
          className="btn-green"
          onClick={(e) => sendToUser(e)}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() =>
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            })
          }
        />
      </div>
    </form>
  );
};

export default FundAccount;
