import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExpensesType } from "../../Services/apis";
import {
  createExpense,
  getExpenseById,
  getAllExpenses,
} from "../../Redux/actions/expenses";
import { toast } from "react-toastify";
import { SHOW_MODAL } from "../../Redux/types";

const EditExpense = ({ toggle, item, id }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const expenseData = useSelector((state) => state.expenses);
  const { oneExpense, loading } = expenseData;
  const expenseDetail = oneExpense && oneExpense ? oneExpense : [];

  const { user } = useSelector((state) => state.auth);

  const [inputObject, setObject] = useState({
    date: "",
    expenseType_id: "",
    expense: "",
    branch_id: "",
    user_id: "",
    amount: "",
    comment: "",
  });

  useEffect(() => {
    dispatch(getExpenseById(id));
  }, [dispatch]);

  useEffect(() => {
    const { branch, expensetype } = expenseDetail;

    setObject({
      date: expenseDetail?.date,
      expenseType_id: expensetype?.id,
      expense: expensetype?.id,
      branch_id: branch?.id,
      user_id: user?.id,
      amount: expenseDetail?.amount,
      comment: expenseDetail?.comment,
    });
  }, [expenseDetail]);

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };
  const creatExpense = async (e) => {
    e.preventDefault();
    const payload = {
      date: new Date(),
      expenseType_id: inputObject.expense,
      branch_id: user?.id,
      user_id: user?.id,
      amount: inputObject.amount,
      comment: inputObject.comment,
    };

    await dispatch(createExpense(payload));
    await dispatch(getAllExpenses());
    await dispatch({
      type: SHOW_MODAL,
      payload: {
        open: false,
      },
    });
  };

  useEffect(() => {
    fetchExpenseType();
  }, [dispatch]);

  const fetchExpenseType = () => {
    setLoading(true);
    getExpensesType()
      .then((r) => {
        if (r.success) {
          setDetails(r.data);
          setLoading(false);
        } else {
          toast.error(r.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Internal server eror");
        setLoading(false);
      });
  };

  return (
    <form className="expense-form">
      <div className="expense-input">
        <label>EXPENSE</label>
        <select onChange={handleChange} name="expense">
          <option value={inputObject.expense}>{inputObject.expense}</option>
          {isLoading
            ? "Loading..."
            : details?.map((type) => (
                <option value={type?.id} key={type?.id}>
                  {type?.title}
                </option>
              ))}
        </select>
      </div>
      <div className="expense-input">
        <label>AMOUNT</label>
        <input
          type="number"
          onChange={handleChange}
          value={inputObject.amount}
          name="amount"
        />
      </div>
      <div className="expense-input">
        <label>COMMENT</label>
        <input
          type="text"
          onChange={handleChange}
          value={inputObject.comment}
          name="comment"
        />
      </div>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value="SAVE"
          className="btn-green"
          onClick={(e) => creatExpense(e)}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() =>
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            })
          }
        />
      </div>
    </form>
  );
};

export default EditExpense;
