import React, { Fragment, useEffect } from "react";
import { RightSide } from "../Components/RightSide";

export default function InPlay({ children }) {
 
  useEffect(() => {
    document.body.classList = "bodyMain en-GB oddsasync Logged";
  }, []);

  return (
    <Fragment>
      <div className="spacer5"></div>
      <table cellPadding="0" cellSpacing="0" id="tblMainLive">
        <tbody>
          <tr>
            <td className="tdCNLive">
              <div className="live-wrapper" id="live-root">
                {children}
              </div>
            </td>
            <td className="tdDX">
              <RightSide />
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
}
