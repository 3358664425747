import React, { useState, useEffect } from "react";
import { getAllLivescore, getLivescore } from "../../Services/apis";
import LiveScoreCard from "../../Components/LiveScoreCard";
import "../../Styles/_livescore.scss";
import * as _ from "lodash";

const LiveScore = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [allLive, setAllLive] = useState([]);
  const [live, setLive] = useState([]);
  const [loading, setLoading] = useState([]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
    fetchLive();
  };

  useEffect(() => {
    fetchAllLive();
  }, []);

  const fetchAllLive = () => {
    setLoading(true);
    getAllLivescore()
      .then((response) => {
        setLoading(false);
        setAllLive(response);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchLive = () => {
    setLoading(true);
    getLivescore()
      .then((response) => {
        setLoading(false);
        setLive(response);
      })
      .catch((err) => {
        
        setLoading(false);
      });
  };

  useEffect(() => {
    groupLeague(allLive);
  }, [live]);

  const groupLeague = (data) => {
    let event = [];
    let ArrKeyHolder = [];
    let Arr = [];

    data.forEach(function (item) {
      ArrKeyHolder[item.section.name] = ArrKeyHolder[item.section.name] || {};
      let obj = ArrKeyHolder[item.section.name];
      if (Object.keys(obj).length === 0) Arr.push(obj);

      obj.LeagueName = item?.section?.name;
      obj.category = item?.category;
      obj.season = item?.season?.name || [];
      obj.events = obj.events || [];
      obj.events.push(item);
    });
    return Arr;
  };

  return (
    <div className="livescore">
      <div className="livescore-tab">
        <button
          className={
            activeTab === "All"
              ? `active bg-blue livescore-button`
              : "bg-blue livescore-button"
          }
          onClick={() => toggleTab("All")}
        >
          All
        </button>
        <button
          className={
            activeTab === "Live"
              ? `active bg-blue livescore-button`
              : "bg-blue livescore-button"
          }
          onClick={() => toggleTab("Live")}
        >
          Live
        </button>
      </div>
      {loading ? (
        <h4 className="loader">Loading...</h4>
      ) : (
        <div className="livescore-tab-content">
          {activeTab === "All" ? (
            <>
              {allLive &&
                groupLeague(allLive)?.map((item) => (
                  <LiveScoreCard type={activeTab} liveItem={item} />
                ))}
            </>
          ) : (
            ""
          )}
          {activeTab === "Live" && (
            <>
              {live &&
                groupLeague(live)?.map((item) => (
                  <LiveScoreCard type={activeTab} liveItem={item} />
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default LiveScore;
